import React from 'react'
import Link from 'gatsby-link'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/pro-regular-svg-icons'

function properCase(str) {
  return str
    .split('-')
    .map(s => s.slice(0, 1).toUpperCase() + s.slice(1))
    .join(' ')
}

const Sitemap = ({ data }) => {
  const news = data.news.edges
  const heroImage = data.heroImage
  const legal = data.legal.edges
  const services = data.services.edges

  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-40"
          gradientClass="mask d-flex justify-content-center align-items-center gradient"
          image={heroImage.childImageSharp.gatsbyImageData}
          title="UNICOM Capital sitemap"
          subtitle=""
          type="sitemap"
        />
      </div>
      <section className="bg-white">
        <MDBContainer>
          <MDBRow>
            <MDBCol md="12">
              <MDBRow>
                <MDBCol md="6" lg="4" className="mb-5">
                  <h3 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-small mt-lg-5"> Home
                    <Link to="/" className="ml-2 linkedin"> <FontAwesomeIcon icon={faLink} /> </Link>
                  </h3>
                  <h3 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-small mt-lg-5"> Company </h3>
                  <p className="font-w-400 text-medium"> <Link to="/about/" className="effect-noline"> About us </Link> </p>
                  <p className="font-w-400 text-medium"> <Link to="/contact/" className="effect-noline"> Contact us </Link> </p>
                  <p className="font-w-400 text-medium"> <Link to="/about/corry-hong-founder-and-ceo/" className="effect-noline"> Corry Hong - Founder and CEO </Link> </p>
                  <p className="font-w-400 text-medium"> <Link to="/about/leadership/" className="effect-noline"> Leadership </Link> </p>
                
                  <h3 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-small mt-lg-5"> UNICOM Global divisions
                    <Link to="/unicom-global/divisions/" className="ml-2 linkedin"> <FontAwesomeIcon icon={faLink} /> </Link>
                  </h3>

                  <h3 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-small mt-lg-5"> Search
                    <Link to="/search/" className="ml-2 linkedin"> <FontAwesomeIcon icon={faLink} /> </Link>
                  </h3>

                  <h3 className="font-alt font-w-700 letter-spacing-1 title-small mt-5"> Privacy &amp; legal </h3>
                  {legal.map(({ node }, index) => (
                    <p className="font-w-400 text-medium" key={index}>
                      <Link to={node.fields.slug} className="effect-noline"> {properCase(node.frontmatter.title)} </Link>
                    </p>
                  ))}
                </MDBCol>

                <MDBCol md="6" lg="4" className="mb-5">
                  <h3 className="font-alt font-w-700 letter-spacing-1 title-small mt-5"> Services
                    <Link to="/services/" className="ml-2 linkedin"> <FontAwesomeIcon icon={faLink} /> </Link>
                  </h3>
                  {services.map(({ node }, index) => (
                    <p className="font-w-400 text-medium" key={index}>
                      <Link to={node.fields.slug} className="effect-noline"> {properCase(node.frontmatter.title)} </Link>
                    </p>
                  ))}

                  <h3 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-small mt-lg-5"> PartnerWorld Advantage
                    <Link to="/partnerworld-advantage/" className="ml-2 linkedin"> <FontAwesomeIcon icon={faLink} /> </Link>
                  </h3>
                </MDBCol>

                <MDBCol md="6" lg="4" className="mb-5">
                  <h3 className="font-alt font-w-700 letter-spacing-1 title-small mt-5"> Newsroom
                    <Link to="/news/" className="ml-2 linkedin"> <FontAwesomeIcon icon={faLink} /> </Link>
                  </h3>
                  {news.map(({ node }, index) => (
                    <p className="font-w-400 text-medium" key={index}>
                      <Link to={`/news/${node.slug}/`} className="effect-noline" > {properCase(node.title)} </Link>
                    </p>
                  ))}
                  <p className="font-w-400 text-medium">
                    <Link to="/news/" className="effect-noline"> more news... </Link>
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    </Layout>
  )
}
export default Sitemap 

export function Head({ data, location }) {
  const heroImage = data.heroImage
  return (
    <SEO
      title="UNICOM Capital - Sitemap"
      description="Use this sitemap to find the content you're looking for and then navigate your way around the UNICOM Capital website."
      url={location.pathname} 
      image={heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
      canonical={location.pathname}                        
    />
  )
}

export const query = graphql`
  query SitemapQuery {
    news: allContentfulNews(
      filter: {
        publishTo: {
          elemMatch: { url: { eq: "https://www.unicom-capital.com" } }
        }
      }
      sort: { fields: date, order: DESC }
      limit: 5
    ) {
      edges {
        node {
          slug
          title
          date
        }
      }
    }
    legal: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "legal" } } }
      sort: { fields: frontmatter___title, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
    services: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "services" } } }
      sort: { fields: frontmatter___sequence, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
    heroImage: file(name: { eq: "sitemap-banner" }) {
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }
  }
`
